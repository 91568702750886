"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$validations, _vm$validations2, _vm$validations3, _vm$validations4, _vm$validations5, _vm$validations6;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "cols-form tab-card-validation-face-component"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card", {
    staticClass: "card-detail-image"
  }, [_c("div", {
    staticClass: "columns is-flex"
  }, [_c("div", {
    staticClass: "column col-img"
  }, [_vm.imageUrl((_vm$validations = _vm.validations) === null || _vm$validations === void 0 ? void 0 : _vm$validations.photo) ? _c("img", {
    staticClass: "img-raised",
    attrs: {
      src: _vm.imageUrl((_vm$validations2 = _vm.validations) === null || _vm$validations2 === void 0 ? void 0 : _vm$validations2.photo)
    }
  }) : _c("img", {
    staticClass: "img-raised",
    attrs: {
      src: require("@/assets/images/avatar-default.png")
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "column col-details-text"
  }, [_c("h2", [_vm._v("Spoofing / Liveness: " + _vm._s(((_vm$validations3 = _vm.validations) === null || _vm$validations3 === void 0 ? void 0 : _vm$validations3.spoofing) || "--"))]), _vm._v(" "), _c("h2", [_vm._v("Facematch: " + _vm._s(((_vm$validations4 = _vm.validations) === null || _vm$validations4 === void 0 ? void 0 : _vm$validations4.facematch) || "--"))]), _vm._v(" "), _c("h2", [_vm._v("Documentoscopia: " + _vm._s(((_vm$validations5 = _vm.validations) === null || _vm$validations5 === void 0 ? void 0 : _vm$validations5.documentoscopia) || "--"))]), _vm._v(" "), _c("h2", [_vm._v("Score: " + _vm._s(((_vm$validations6 = _vm.validations) === null || _vm$validations6 === void 0 ? void 0 : _vm$validations6.documentoscopia) || "--"))]), _vm._v(" "), _c("span", {
    staticClass: "tag is-primary mt-4"
  }, [_vm._v(_vm._s(_vm.validations.status))])])]), _vm._v(" "), _c("div", {
    staticClass: "columns is-flex"
  }, [_c("div", {
    staticClass: "column is-flex is-justify-content-flex-end is-align-content-flex-end"
  }, [_c("div", {
    staticClass: "is-justify-content-flex-end is-align-content-flex-end"
  }, [_c("b-button", {
    attrs: {
      type: "is-success"
    },
    on: {
      click: _vm.openDialogAprove
    }
  }, [_vm._v("APROVAR MANUALMENTE")])], 1)])])])], 1)])]), _vm._v(" "), _c("b-modal", {
    attrs: {
      "has-modal-card": "",
      "full-screen": "",
      "can-cancel": false
    },
    model: {
      value: _vm.isComponentModalActive,
      callback: function callback($$v) {
        _vm.isComponentModalActive = $$v;
      },
      expression: "isComponentModalActive"
    }
  }, [_c("modal-form", _vm._b({}, "modal-form", _vm.formProps, false))], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;