"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/components");
var _validators = require("vuelidate/lib/validators");
var _default = exports.default = {
  name: 'ModalFormDossie',
  components: {
    AppInput: _components.AppInput
  },
  data: function data() {
    return {
      form: {
        description: ''
      }
    };
  },
  validations: function validations() {
    return {
      form: {
        description: {
          required: _validators.required
        }
      }
    };
  },
  methods: {
    close: function close() {
      this.$parent.close();
    },
    checkForm: function checkForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Por favor, verifique os campos <b>obrigat\xF3rios</b>!",
          position: 'is-top',
          type: 'is-danger'
        });
      } else {
        // TODO: Chamar endpoint para registrar aprovação
        this.close();
      }
    }
  }
};