"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-dash"
  }, [_c("b-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("b-tab-item", {
    attrs: {
      label: "Dashboard"
    }
  }, [_c("Dashboard")], 1), _vm._v(" "), _c("b-tab-item", {
    attrs: {
      label: "Ver Mais"
    }
  }, [_c("SeeMore")], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;