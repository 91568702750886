"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var _default2 = exports.default = {
  name: 'TableLastRequestsList',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      checkedRows: [],
      isModalActive: false,
      trashObject: null,
      perPage: 10,
      selected: null
    };
  },
  computed: {
    paginated: function paginated() {
      return this.list.length > this.perPage;
    }
  },
  watch: {
    selected: function selected(val) {
      this.$router.push({
        path: "/detail-dashboard-item-list/".concat(val.protocol)
      });
    }
  },
  methods: {
    trashModalOpen: function trashModalOpen(obj) {
      this.trashObject = obj;
      this.isModalActive = true;
    },
    trashConfirm: function trashConfirm() {
      this.isModalActive = false;
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      });
    },
    trashCancel: function trashCancel() {
      this.isModalActive = false;
    },
    translateStatusProtocolHive: function translateStatusProtocolHive(row) {
      return (0, _utils.translateStatusProtocolHive)(row.status, row.isWebhook);
    }
  }
};